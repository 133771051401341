import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import axios from "axios";
import VueAxios from "vue-axios";
import SocketIO from "socket.io-client";
import VueSocketIO from "vue-socket.io";
import AppLayout from '@/layouts/AppLayout'

Vue.config.productionTip = false

axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;

axios.interceptors.request.use((config) => {
  if (!config.url.includes('http')) {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.common["Authorization"] = `Bearer ${token}`;
    } else {
      delete config.headers.common["Authorization"];
    }
  }
  return config;
});

Vue.use(
  new VueSocketIO({
    connection: SocketIO(process.env.VUE_APP_WEB_SOCKET_URL),
  })
);

Vue.use(VueAxios, axios);

// if (process.env.NODE_ENV === 'development') {
//   const VueAxe = require('vue-axe').default
//   Vue.use(VueAxe, {
//     clearConsoleOnUpdate: false,
//     runOptions: {
//       reporter: 'v2'
//     }
//   })
// }

Vue.component('AppLayout', AppLayout)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')

import Vue from "vue";
import VueRouter from "vue-router";
import Meta from 'vue-meta'
import store from "../store"

Vue.use(VueRouter);
Vue.use(Meta)

const userRoutes = [
    {
        path: "/login",
        name: "Login",
        component: () => import("../views/users/user-login.vue"),
        meta: {
            requiresAuth: false,
            layout: 'AppLayoutClientArea'
        },
    },
]

const mainRoutes = [
    {
        path: "/",
        name: "Home",
        component: () => import("../views/main-home.vue"),
        meta: {
            requiresAuth: false
        }
    },
    {
        path: "/contact",
        name: "Contact",
        component: () => import("../views/main-contact.vue"),
        meta: {
            requiresAuth: false
        }
    },
    {
        path: "/about",
        name: "About",
        component: () => import("../views/main-about.vue"),
    },
    {
        path: "/services",
        name: "Services",
        component: () => import("../views/main-services.vue"),
    },
    // Automation Solutions
    {
        path: '/services/automation-solutions',
        name: 'Automation Solutions',
        component: () => import('@/views/services/automation-solutions.vue')
    },
    // Data Acquisition and Analysis
    {
        path: '/services/data-acquisition-and-analysis',
        name: 'Data Acquisition and Analysis',
        component: () => import('@/views/services/data-acquisition-and-analysis.vue')
    },
    // Custom Programming
    {
        path: '/services/custom-programming',
        name: 'Custom Programming',
        component: () => import('@/views/services/custom-programming.vue')
    },
    // Web Development
    {
        path: '/services/web-development',
        name: 'Web Development',
        component: () => import('@/views/services/web-development.vue')
    },
    // Application Development
    {
        path: '/services/application-development',
        name: 'Application Development',
        component: () => import('@/views/services/application-development.vue')
    },
    // IT Consulting
    {
        path: '/services/it-consulting',
        name: 'IT Consulting',
        component: () => import('@/views/services/it-consulting.vue')
    }
]

const clientAreaRoutes = [
    {
        path: "/client-portal",
        name: "Client Portal",
        component: () => import("../views/client-portal.vue"),
        meta: {
            requiresAuth: true,
            layout: 'AppLayoutClientArea'
        }
    },
    {
        path: "/cpg",
        name: "CPG",
        component: () => import("../views/main-cpg.vue"),
        meta: {
            requiresAuth: true,
            layout: 'AppLayoutClientArea'
        }
    },
    {
        path: "/firms",
        name: "Firms",
        component: () => import("../views/main-firm.vue"),
        meta: {
            requiresAuth: true,
            layout: 'AppLayoutClientArea'
        }
    },
    {
        path: "/view/firms",
        name: "Firms",
        component: () => import("../views/main-firm-view.vue"),
        meta: {
            requiresAuth: true,
            layout: 'AppLayoutClientArea'
        }
    },
    {
        path: "/local",
        name: "Local",
        component: () => import("../views/main-local.vue"),
        meta: {
            requiresAuth: true,
            layout: 'AppLayoutClientArea'
        }
    },
]

const clientRoutes = []

const router = new VueRouter({
    routes: [
        ...userRoutes,
        ...mainRoutes,
        ...clientAreaRoutes,
        ...clientRoutes
    ],
    mode: "history",
});

router.beforeEach(async (to, from, next) => {
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth)

    if (requiresAuth) {
        if (store.getters["users/isAuthenticated"]) {
            if (store.getters["users/user"] && Object.keys(store.getters["users/user"]).length < 1) {
                await store.dispatch("users/me")
                if (store.getters["users/meError"] === 'Invalid token.') {
                    next("/login")
                    return
                }
            }
            next()
            return
        }
        next("/login")
    } else {
        next()
    }
})


export default router;

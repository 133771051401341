import Vue from "vue";

const actions = {
    async login({ commit }, payload) {
        const { data } = await Vue.prototype.axios.post('users/login', payload)

        if (data.success === true) {
            localStorage.setItem("token", data.token)
            localStorage.setItem("user", JSON.stringify(data.user))
            commit("loginSuccess", data)
        } else {
            commit("loginError", data.err)
        }
    },

    async me({ commit }) {
        try {
            const { data } = await Vue.prototype.axios.get('users/me')
            commit("meSuccess", data)
        } catch (err) {
            let msg = err.response.data.message || "Failed to retrieve user."
            commit("meError", msg)
        }
    },

    async logout({ commit }) {
        localStorage.removeItem("token")
        commit("logoutSuccess")
    },
}

const mutations = {
    loginSuccess(state, data) {
        state.loginError = null
        state.user = data.user
        state.token = data.token
    },

    loginError(state, err) {
        state.loginError = err
        state.user = {}
        state.token = ""
    },

    meSuccess(state, data) {
        state.user = data.user
    },
    meError(state, err) {
        state.meError = err
        state.user = {}
        state.token = ""
    },

    logoutSuccess(state) {
        state.user = {}
        state.token = ""
    }
}

const getters = {
    isAuthenticated: state => !!state.token,
    user: state => state.user,
    token: state => state.token,
    meError: state => state.meError,
}

const state = () => ({
    user: JSON.parse(localStorage.getItem("user")) || null,
    token: localStorage.getItem("token") || "",
    loginError: null,
    meError: null
})

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
